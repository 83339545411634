import { AbilityBuilder, Ability } from '@casl/ability'

export default function defineAbilityFor(coin = 'btc') {
    const { can, rules } = new AbilityBuilder(Ability);

    // defined permissions
    if (coin === 'btc' || coin === 'ltc') {
        can(['use'], 'btc')
    }

    if (coin === 'eth' || coin === 'bnb' || coin === 'trx') {
        can(['use'], 'eth')
    }

    return new Ability(rules);
}
