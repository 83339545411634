import apiService from '@/utils/api-client'
import config from "@/utils/appConfig";

export default {
	// eslint-disable-next-line no-unused-vars
  getUsersList({ commit }, { count, skip }) {
    return apiService.get('admin/users-list', { params: { count, skip }, baseURL: config.VUE_APP_API_USERS_URL })
  },
  // eslint-disable-next-line no-unused-vars
  setActiveUser({ commit }, data) {
    return apiService.put('admin/set-active-status', data, { baseURL: config.VUE_APP_API_USERS_URL })
  },
  // eslint-disable-next-line no-unused-vars
  getUser({ commit }, id) {
    return apiService.get(`admin/users/${id}`, { baseURL: config.VUE_APP_API_USERS_URL })
  },
  editUser(ctx, { id, body }) {
    return apiService.patch(`admin/users/${id}`, body, { baseURL: config.VUE_APP_API_USERS_URL })
  },
  deleteUser(ctx, id) {
    return apiService.delete(`admin/users/${id}`, { baseURL: config.VUE_APP_API_USERS_URL })
  },
	// eslint-disable-next-line no-unused-vars
	sendInviteUser({ commit }, { email, role, activeTo = null }) {
    return apiService.post('admin/send-invitation', { email, role, activeTo, siteUrl: window.location.origin }, { baseURL: config.VUE_APP_API_USERS_URL })
  },
	// eslint-disable-next-line no-unused-vars
	resetPassword({ commit }, email) {
    return apiService.get(`users/reset-password/${email}`, { params: { siteUrl: window.location.origin }, baseURL: config.VUE_APP_API_USERS_URL })
  },
}
