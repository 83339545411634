import apiService from '@/utils/api-client'

export default {
  // eslint-disable-next-line no-unused-vars
  async getAddressesMonitoring({ commit }, { skip = 0, count = 10, address = undefined }) {
    try {
      const data = apiService.get('monitoring/addressToMonitor', { params: { skip, count, address } })
      // commit('ADD_HISTORY_ENTRY', { path: `api/tx/visualCases`})
      return data
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getListSubscriptions({ commit }, { skip = 0, count = 10, address = undefined }) {
    try {
      const data = apiService.get('monitoring/listSubscriptions', { params: { skip, count, address } })
      // commit('ADD_HISTORY_ENTRY', { path: `api/tx/visualCases`})
      return data
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async addToMonitoring({ commit }, { address, file, checkLastTen, blockchain = 'BTC' }) {
    try {
      const data = apiService.post('monitoring/addressToMonitor', { address, file, checkLastTen, blockchain })
        return data
    } catch (error) {
        console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async deleteAddressFromMonitoring({ commit }, { address }) {
    try {
      const data = apiService.delete(`monitoring/addressToMonitor/${address}`)
      return data
    } catch (error) {
      console.error(error)
    }
  },
}
