import apiService from '@/utils/api-client'
import { sendFormatter } from "@/utils/text-formatter";

export default {
  // eslint-disable-next-line no-unused-vars
  async getTypes({ commit }, { name, sortField, count, skip, sortOrder, search }) {
    try {
      const data = apiService.get(`labeling/getTypes`, { params: { name, sortField, count, skip, sortOrder, search } })
      return data
    } catch (error) {
      console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async addType({ commit }, { name, score }) {
    const formattedName = sendFormatter(name)

    try {
      return apiService.post(`labeling/addType`, { name: formattedName, score })
    } catch (error) {
      console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async mergeTypes({ commit }, { toType, fromTypes, score }) {
    const formattedToType = sendFormatter(toType)
    const formattedFromType = fromTypes.map(type => sendFormatter(type))

    try {
      return apiService.post(`labeling/mergeTypes`, { toType: formattedToType, fromTypes: formattedFromType, score })
    } catch (error) {
      console.error(error)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async editType({ commit }, { name, newName, score, skipCheck = false }) {
    const formattedName = sendFormatter(name)
    const formattedNewName = sendFormatter(newName)

    try {
      const data = apiService.post(`labeling/editType`, { name: formattedName, newName: formattedNewName, score, skipCheck })
      return data
    } catch (error) {
      console.error(error)
    }
  },

  deleteType(ctx, { names }) {
    const formattedNames = names.map(name => sendFormatter(name))

    return apiService.delete(`labeling/deleteType`, { data: { names: formattedNames } });
  },
}
