<template>
  <div
    id="app"
    :class="{'clear-layout': isClearHeader}"
  >
    <div class="flex">
      <div>
        <Sidebar :class="{'hide-header': isClearHeader}" />
      </div>
      <div class="app-root">
        <gl-header :class="{'hide-header': isClearHeader}" />
        <transition name="fade">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import {mapMutations, mapState} from 'vuex'
// Components
import GlHeader from '@/components/layout/gl-header'
import Sidebar from '@/components/sidePanelMenu'
// Services
import defineAbilityFor from "@/services/defineAbilityFor";
// Static
import themes from "@/assets/styles/themes.json";
import config from "@/utils/appConfig";

export default {
  components: {
    GlHeader,
    Sidebar,
  },
  data() {
    return {
      isClearHeader: false,
      themesData: themes,
      resultThemeData: {}
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'coinData', 'currencyList'])
  },
  watch: {
    coinType: {
      handler(role) {
        this.updateAbilities(role)
      },
      immediate: true,
    },
    $route: {
      handler(route) {
        this.isClearHeader = route.name === 'register' || route.name === 'login'
      },
      immediate: true,
    },
  },
  async created() {
    this.resultThemeData = this.mergeObjects(this.themesData['default'], this.themesData[config.VUE_APP_THEME])
    const faviconLink = document.getElementById("favicon");
    if (faviconLink && config.VUE_APP_THEME) {
      faviconLink.href = `/assets/themes/${config.VUE_APP_THEME}/favicon.ico`;
    }
    this.setGlobalStyles()
    this.SET_COIN_CURRENCY_LIST(JSON.parse(process.env.VUE_APP_MANIFEST))
    const documentHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', documentHeight)
    documentHeight()
    const coin = this.currencyList[0]
    this.SET_COIN_DATA(coin)
    this.SET_COIN_TYPE(coin.key)
    await this.updateAbilities(this.coinType)
  },
  methods: {
    ...mapMutations({
      SET_COIN_TYPE: 'analytics/SET_COIN_TYPE',
      SET_COIN_DATA: 'analytics/SET_COIN_DATA',
      SET_COIN_CURRENCY_LIST: 'analytics/SET_COIN_CURRENCY_LIST',
    }),
    renameObjectKeys(obj, prefix) {
      const result = {};

      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          result[prefix + key] = obj[key];
        }
      }

      return result;
    },
    setGlobalStyles() {
      const { colors } = this.resultThemeData;
      const formattedColors = this.renameObjectKeys(colors, '--')
      const styleTag = document.createElement('style');

      styleTag.textContent = generateCSSFromJSON(formattedColors);
      document.head.appendChild(styleTag);

      function generateCSSFromJSON(jsonData) {
        let css = ':root {\n';

        for (const key in jsonData) {
          // eslint-disable-next-line no-prototype-builtins
          if (jsonData.hasOwnProperty(key)) {
            css += `${key}: ${jsonData[key]};\n`;
          }
        }

        css += '}';
        return css;
      }
    },
    mergeObjects(baseObj, newObj) {
      const result = baseObj;

      for (const key in newObj) {
        // eslint-disable-next-line no-prototype-builtins
        if (newObj.hasOwnProperty(key)) {
          if (typeof newObj[key] === 'object' && !Array.isArray(newObj[key])) {
            result[key] = this.mergeObjects(baseObj[key] || {}, newObj[key]);
          } else {
            // eslint-disable-next-line no-prototype-builtins
            result[key] = baseObj.hasOwnProperty(key) && baseObj[key] === newObj[key] ? baseObj[key] : newObj[key];
          }
        }
      }

      return result;
    },
    updateAbilities() {
      this.$ability.update(defineAbilityFor(this.coinType).rules);
    },
  },
}
</script>
